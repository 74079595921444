<template>
  <el-dialog
      v-model="isShow"
      title="选择故障现象"
      width="30%"
      :before-close="handleClose"
  >
    <el-checkbox-group v-model="selectedFault">
      <el-row :gutter="10">
        <el-col v-for="fault in dict.type.work_order_fault_phenomenon_type" :span="12">
          <el-checkbox :label="fault.value">{{ fault.label }}</el-checkbox>
        </el-col>
      </el-row>
    </el-checkbox-group>
    <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleSubmit">
              确定
            </el-button>
          </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "SelectFault",
  dicts: ['work_order_fault_phenomenon_type'],
  props: {
    selectedFault: {
      required: true,
      type: Array,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:visible', 'completed'],
  data() {
    return {

    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  methods: {
    handleClose() {
      this.isShow = false
    },

    handleSubmit() {
      this.$emit('completed', this.selectedFault)
      this.isShow = false
    }
  },
}
</script>

<style scoped>

</style>
