import request from '@/utils/request'

// 查询设备定位列表
export function listDeviceLocation(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/device_location/list',
        method: 'get',
        params: query
    })
}

// 查询设备定位详细
export function getDeviceLocation(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/device_location/' + id,
        method: 'get'
    })
}

// 新增设备定位
export function addDeviceLocation(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/device_location',
        method: 'post',
        data: data
    })
}

// 修改设备定位
export function updateDeviceLocation(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/device_location',
        method: 'put',
        data: data
    })
}

// 删除设备定位
export function delDeviceLocation(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/device_location/' + id,
        method: 'delete',
        data: data
    })
}

// 上线设备
export function onlineDevice(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/device_location/' + id,
        method: 'patch',
        data: data
    })
}

// 提交设备
export function submitDeviceLocation(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/device_location/submit/' + id,
        method: 'patch',
        data: data
    })
}

// 查询设备编号选项
export function getDeviceNoOption() {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/device_location/device_no_option',
        method: 'get'
    })
}
// 查询设备收益数据
export function getDeviceLocationIncomeStatisticsData(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/device_location/' + id + '/income_data',
        method: 'get'
    })
}
